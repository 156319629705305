
.paper-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 0;
    color: #333;
    .head {
        position: relative;
        font-size: 22px;
        text-align: center;
        .total-score {
            margin-top: 5px;
            font-size: 16px;
            color: #666;
        }
        .back-button {
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
        }
    }
    .scroll-content {
        flex: 1;
        overflow: hidden;
    }
}
